var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user&&_vm.user.userId)?_c('div',[_c('div',{staticClass:"user-card",staticStyle:{"margin-bottom":"20px"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v("为")]),(_vm.user.headImg)?_c('img',{attrs:{"src":_vm.user.headImg,"alt":""}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.user.userName)),(_vm.user.userPhone)?_c('span',[_vm._v("("+_vm._s(_vm.user.userPhone)+")")]):_vm._e(),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(({'moeny': '充值', 'voucher': '构建充值卡'})[_vm.type]))])])]),_c('a-form-model',{ref:"form",attrs:{"layout":"horizontal","label-col":{ span: 5 },"wrapper-col":{ span: 19 },"model":_vm.model,"colon":false},on:{"submit":_vm.submit},nativeOn:{"submit":function($event){$event.preventDefault();}}},[(_vm.type === 'money')?[_c('a-form-model-item',{attrs:{"label":"充值金额","prop":"amount","rules":[
        { required: true, message: '请输入' },
        { pattern: /^[0-9]+(\.[0-9]{1,2})?$/, message: '格式不正确' }
      ]}},[_c('a-input',{attrs:{"placeholder":"请输入充值金额"},model:{value:(_vm.model.amount),callback:function ($$v) {_vm.$set(_vm.model, "amount", $$v)},expression:"model.amount"}})],1)]:(_vm.type === 'voucher')?[_c('a-form-model-item',{attrs:{"label":"充值卡规格","prop":"productSku","rules":[
        { required: true, message: '请选择充值卡及规格' }
      ]}},[_c('sku-select',{model:{value:(_vm.model.productSku),callback:function ($$v) {_vm.$set(_vm.model, "productSku", $$v)},expression:"model.productSku"}})],1),_c('a-form-model-item',{attrs:{"label":"数量","prop":"num","rules":[
        { required: true, message: '请输入' },
        { pattern: /^[1-9][0-9]*$/, message: '格式不正确' }
      ]}},[_c('a-input',{attrs:{"placeholder":"请输入数量"},model:{value:(_vm.model.num),callback:function ($$v) {_vm.$set(_vm.model, "num", $$v)},expression:"model.num"}})],1),_c('a-form-model-item',{attrs:{"label":"充值说明","prop":"buyNote"}},[_c('a-input',{attrs:{"placeholder":"请输入说明"},model:{value:(_vm.model.buyNote),callback:function ($$v) {_vm.$set(_vm.model, "buyNote", $$v)},expression:"model.buyNote"}})],1)]:_vm._e(),_c('a-form-model-item',{attrs:{"label":" "}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("提交")])],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }